import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => {
  return <Inner>{children}</Inner>;
};

const Inner = styled.article`
  padding: 3rem 1.5rem;
`;
