import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { uid } from 'react-uid';

interface Props {
  points: string[];
}

export default ({ points }: PropsWithChildren<Props>) => {
  return (
    <Bullets>
      <Ul>
        {points.map((point) => (
          <Li key={uid(point)}>{point}</Li>
        ))}
      </Ul>
    </Bullets>
  );
};

const Bullets = styled.article`
  padding: 3rem 0 0;
`;

const Ul = styled.ul`
  list-style: unset;
  margin-left: 0.5rem;
`;

const Li = styled.li`
  padding-bottom: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.8;
  padding-left: 1.5rem;

  &:before {
    content: '\u2022';
    color: ${({ theme }) => theme.colors.purple};
    font-weight: bold;
    display: inline-block;
    width: 2em;
    margin-left: -2em;
  }
`;
