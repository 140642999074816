import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import getColor, { Color } from '../../utils/getColor';

interface Props {
  text: string;
  authorImg: string;
  name: string;
  authorRole: string;
  bgColor: Color;
}

export default ({ text, authorImg, name, authorRole, bgColor }: PropsWithChildren<Props>) => {
  return (
    <PullQuoteWrapper>
      <PullQuote bgColor={bgColor}>{text}</PullQuote>
      <AuthorDetails>
        {authorImg && <Author authorImg={authorImg} />}
        <Credentials>
          <Name>{name}</Name>
          <Role>{authorRole}</Role>
        </Credentials>
      </AuthorDetails>
    </PullQuoteWrapper>
  );
};

interface StyledProps {
  bgColor: Color;
}

interface AuthorProps {
  authorImg: string;
}

const AuthorDetails = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: column;
  grid-template-columns: max-content;
`;

const PullQuoteWrapper = styled.div`
  display: grid;
  gap: 3rem;
`;

const Name = styled.h3`
  font-weight: 500;
  font-size: 1.5rem;
`;

const Role = styled.p`
  color: ${({ theme }) => theme.colors.grey700};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.875rem;
`;

const Credentials = styled.div`
  display: grid;
  grid-template-rows: max-content;
  gap: 0.5rem;
  align-self: center;
`;

const Author = styled.figure<AuthorProps>`
  border-radius: 50%;
  object-fit: contain;
  width: 4rem;
  height: 4rem;
  background: ${({ authorImg }) => `url(${authorImg}
  )`};
  background-repeat: no-repeat;
  background-size: 4rem;
`;

const PullQuote = styled.q<StyledProps>`
  font-size: 2.5rem;
  font-weight: 400;
  position: relative;
  padding-left: 1.5rem;
  line-height: 1.2;
  display: block;
  color: ${({ theme }) => theme.colors.grey500};

  @media all and (max-width: 48em) {
    font-size: 2rem;
  }

  @media all and (max-width: 32em) {
    font-size: 1.25rem;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    background: ${({ bgColor }) => getColor(bgColor)};
    width: 4px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;
