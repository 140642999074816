import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import getColor, { Color } from '../../utils/getColor';

interface Props {
  tag: 'h1' | 'h2' | 'h3' | 'h4';
  text: string;
  pipeColor: Color;
}

export default ({ tag, text, pipeColor }: PropsWithChildren<Props>) => {
  switch (tag) {
    case 'h1':
      return <H1>{text}</H1>;
    case 'h2':
      return <H2>{text}</H2>;
    case 'h3':
      return <H3 pipeColor={pipeColor}>{text}</H3>;
    default:
      return <H3 pipeColor={pipeColor}>{text}</H3>;
  }
};

interface StyledProps {
  pipeColor: Color;
}

const H1 = styled.h1`
  font-size: 2.5rem;
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
`;

const H2 = styled.h1`
  font-size: 2.5rem;
  margin: 0;
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
  padding: 3rem 0 1.5rem;
`;

const H3 = styled.h3<StyledProps>`
  font-size: 2.5rem;
  margin: 0;
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
  padding-left: 1.5rem;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 1.5rem;

  @media all and (max-width: 48em) {
    font-size: 1.5rem;
  }

  @media all and (max-width: 32em) {
    font-size: 1.25rem;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    background: ${({ pipeColor }) => getColor(pipeColor)};
    width: 4px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;
