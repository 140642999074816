import React, { PropsWithChildren, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import CountUp from 'react-countup'
import { TrendingUp } from 'react-feather'
import { InView } from 'react-intersection-observer'
import Container from '../../Container'

// import { darken } from 'polished';

interface Props {
  figure: string
  bgColor: string
  bannerHeight: '30vh' | '40vh' | '50vh' | '60vh' | '70vh' | '80vh' | '90vh' | '100vh'
  quote?: string
  statistic: string
  authorImg?: string
  authorRole?: string
  authorName?: string
}

export default ({
  figure,
  bgColor,
  bannerHeight,
  quote,
  statistic,
  authorImg,
  authorRole,
  authorName
}: PropsWithChildren<Props>) => {
  const themeContext = useContext(ThemeContext)
  return (
    <Banner bannerHeight={bannerHeight} bgColor={bgColor}>
      <Container>
        <StatisticGrid>
          <SubHead>By the numbers</SubHead>
          <FigureWrapper>
            <FigureContainer>
              <CountUp end={parseInt(figure, 10)} duration={2.75} suffix='%' redraw={false} start={0}>
                {({ countUpRef, start }) => (
                  <InView delay={500} triggerOnce as='div' onChange={inView => inView && start && start()}>
                    <Figure ref={countUpRef} />
                  </InView>
                )}
              </CountUp>
              <TrendingUp size='6rem' color={themeContext.colors.success} />
            </FigureContainer>
            <Statistic>{statistic}</Statistic>
          </FigureWrapper>
          <QuoteWrapper>
            <Quote>{quote}</Quote>
            <AuthorDetails>
              {authorImg && <Author authorImg={authorImg} />}
              <Credentials>
                <Name>{authorName}</Name>
                <Role>{authorRole}</Role>
              </Credentials>
            </AuthorDetails>
          </QuoteWrapper>
        </StatisticGrid>
      </Container>
    </Banner>
  )
}

interface StyledProps {
  textColor: string | undefined
}

interface BannerProps {
  bgColor: string
  bannerHeight: '30vh' | '40vh' | '50vh' | '60vh' | '70vh' | '80vh' | '90vh' | '100vh'
}

interface AuthorProps {
  authorImg: string
}

const StatisticGrid = styled.div`
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem;
  gap: 1.5rem;

  ${({ theme }) => theme.breakpoints.mobile} {
    display: flex;
    flex-direction: column;
  }
`

const Author = styled.img<AuthorProps>`
  width: 3rem;
  height: 3rem;
  background: ${({ authorImg }) => `url(${authorImg}
  )`};
  background-repeat: no-repeat;
  background-size: 3rem;
  border-radius: 50%;
`

const AuthorDetails = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: column;
  grid-template-columns: max-content;
`

const Credentials = styled.div``
const Name = styled.p`
  font-size: 0.875rem;
`
const Role = styled.p`
  font-size: 0.875rem;
`

const FigureContainer = styled.div`
  display: grid;
  grid-template-columns: max-content;
  gap: 1.5rem;
  grid-auto-flow: column;
  align-items: center;
`

const SubHead = styled.span`
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`

const Banner = styled.div<BannerProps>`
  height: ${({ bannerHeight }) => bannerHeight};
  width: 100%;
  background: ${({ theme }) => theme.colors.grey200};
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  display: flex;
  align-items: center;
`

const Figure = styled.p`
  font-weight: 500;
  font-size: 6rem;
  ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 4rem;
  }
`

const FigureWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;
  grid-template-rows: max-content;
`

const Quote = styled.p`
  position: relative;
  padding-left: 1.5rem;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    background: ${({ theme }) => theme.colors.success};
    width: 4px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`

const Statistic = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
`

const QuoteWrapper = styled.div`
  grid-row: 2/3;
  grid-column: 2/2;
  position: relative;
  display: grid;
  gap: 3rem;
  grid-auto-flow: row;
`
