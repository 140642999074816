import React, { PropsWithChildren, useContext } from 'react'
import styled, { useTheme, ThemeContext } from 'styled-components';
import Container from '../../Container'
import { Button } from '../../Button';
import { LogIn } from 'react-feather';
import { graphql, useStaticQuery, Link } from 'gatsby';

interface Props {
  customerName: string
  title: string
  daiseeLogo?: string
  customerLogo?: string
  subtitle: string
  customerUrl: string
  button: string
}

export default ({ customerName, title, subtitle, daiseeLogo, customerLogo, customerUrl, button }: PropsWithChildren<Props>) => {
  const theme = useTheme()
  const themeContext = useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    query CustomerStoryPdf {
      myob: file(relativePath: { eq: "customerStories/MYOB-case-study.pdf" }) {
        publicURL
      }
      employsure: file(relativePath: { eq: "customerStories/EMPLOYSURE-case-study.pdf" }) {
        publicURL
      }
      ampol: file(relativePath: { eq: "customerStories/AMPOL-case-study.pdf" }) {
        publicURL
      }
    }
  `)

  let href
  if (customerName === 'myob'){
    href = data.myob.publicURL
  } 
  if (customerName === 'employsure'){
    href = data.employsure.publicURL
  } 
  if (customerName === 'ampol'){
    href = data.ampol.publicURL
  } 

  return (
    <Hero>
      <Container>
        <HeroHeader>
          <Link to='/customers' >
            <Button
              action="Back"
              btnHeight="2.5rem"
              icon={<LogIn style={{ transform: 'scale(-1,-1)' }} />}
              iconSide="left"
              bgColor={themeContext.colors.grey400}
            />
          </Link>
          {daiseeLogo && customerLogo && (
            <Lockup>
              <img src={daiseeLogo} alt='daisee logo' />
              <Divider />
              <a href={customerUrl}>
                <img src={customerLogo} alt='' />
              </a>
            </Lockup>
          )}
        </HeroHeader>
        <HeroInfo>          
          <Title>{title}</Title>
          <Subtitle>
            <p>{subtitle}</p>
            <br></br>
          </Subtitle>
          <a className='button' href={href} download>
            <Button action={button} btnHeight='auto' bgColor={theme.colors.green} />
          </a>
          <Subtitle>
            <a href={customerUrl}>{customerUrl}</a>
          </Subtitle>
        </HeroInfo>
      </Container>
    </Hero>
  )
}


const Hero = styled.figure`
  background: ${({ theme }) => theme.colors.grey200};
  display: grid;
  gap: 1.5rem;
  align-content: end;
  padding-top: 9rem;
  width: 100%;
  margin-top: -85px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
`

const HeroHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5rem;
`

const Title = styled.h1`
  font-weight: 500;
  font-size: 3rem;
  margin: 0;
  line-height: 1.1;
`

const Lockup = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: 300px;
  grid-template-columns: 1fr 0.25fr 1fr;
  gap: 1rem;
  ${({ theme }) => theme.breakpoints.tablet} {
    width: 200px;
  }
`

const Divider = styled.div`
  width: 2px;
  height: 3rem;
  background: ${({ theme }) => theme.colors.grey300};
  justify-self: center;
`

const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.grey700};
  font-size: 1.5rem;
  line-height: 1.5;

  a {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.purple};
  }
`

const HeroInfo = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  padding: 3rem 1.5rem;
  gap: 2rem;
  align-items: end;
`