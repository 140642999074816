import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { uid } from 'react-uid';

interface Props {
  text: string;
}

export default ({ text }: PropsWithChildren<Props>) => {
  const newText = text.split('\n').map((item) => <P key={uid(item)}>{item}</P>);
  return <BackgroundInfo>{newText}</BackgroundInfo>;
};

const BackgroundInfo = styled.article``;

const P = styled.p`
  line-height: 1.8;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
`;
