import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => {
  return <ContentContainer>{children}</ContentContainer>;
};

const ContentContainer = styled.div`
  max-width: 680px;
  margin: 0 auto;
  flex-grow: 1;
  padding: 1.5rem;
  width: 100%;
`;
