import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

interface Props {
  heading?: string;
  subHeading?: string;
  textColor?: string;
  bgImage?: string;
  bannerHeight: '30vh' | '40vh' | '50vh' | '60vh' | '70vh' | '80vh' | '90vh' | '100vh';
}

export default ({ heading, subHeading, textColor, bgImage, bannerHeight }: PropsWithChildren<Props>) => {
  return (
    <Banner bannerHeight={bannerHeight} textColor={textColor} bgImage={bgImage}>
      <Info>
        <H3 textColor={textColor}>{heading}</H3>
        <P textColor={textColor}>{subHeading}</P>
      </Info>
    </Banner>
  );
};

interface StyledProps {
  textColor: string | undefined;
}

interface BannerProps {
  bgImage?: string | undefined;
  textColor: string | undefined;
  bannerHeight: '30vh' | '40vh' | '50vh' | '60vh' | '70vh' | '80vh' | '90vh' | '100vh';
}

const Banner = styled.div<BannerProps>`
  height: ${({ bannerHeight }) => bannerHeight};
  background: ${({ textColor }) => textColor && textColor};
  background-image: ${({ bgImage }) => bgImage && `url(${bgImage})`};
  object-fit: contain;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: ${({ bgImage }) => (bgImage ? 'rgb(84, 112, 159, 0.3);' : 'rgba(0, 0, 0, 0)')};
    z-index: 1;
  }
`;

const H3 = styled.h3<StyledProps>`
  color: ${({ textColor }) => (textColor ? darken(0.35, textColor) : '#fff')};
  font-size: 2.5rem;
  margin: 0;
  font-weight: 500;
  position: relative;
  z-index: 3;
`;

const P = styled.p<StyledProps>`
  font-size: 1.25rem;
  color: ${({ textColor }) => (textColor ? darken(0.3, textColor) : '#fff')};
  position: relative;
  z-index: 3;
`;

const Info = styled.div`
  padding: 1.5rem;
  display: grid;
  gap: 1.5rem;
`;
