import { PageProps, graphql } from 'gatsby'
import * as React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import PullQuote from '../components/CustomerStories/utils/PullQuote'
import Inner from '../components/CustomerStories/utils/Inner'
import Hero from '../components/CustomerStories/utils/Hero'
import BackgroundInfo from '../components/CustomerStories/utils/BackgroundInfo'
import ContentContainer from '../components/CustomerStories/utils/ContentContainer'
import Banner from '../components/CustomerStories/utils/Banner'
import Heading from '../components/CustomerStories/utils/Heading'
import Bullets from '../components/CustomerStories/utils/Bullets'
import Statistic from '../components/CustomerStories/utils/Statistic'
import Image from '../components/CustomerStories/utils/Image'
import Back from '../components/OurPeople/utils/Back'
import P from '../components/CustomerStories/utils/P'

import Footer from '../components/Footer/Footer'
import Container from '../components/Container'
import { SeoObject, Site } from '../components/Site'
import { Main } from '../components/Styled'

interface Query {
  data: {
    mdx: {
      frontmatter: {
        id: number
        title: string
        metaDescription: string
      }
      body: string
    }
  }
}

// pass common components
const shortcodes = {
  PullQuote,
  Container,
  Inner,
  Hero,
  BackgroundInfo,
  ContentContainer,
  Banner,
  Heading,
  Bullets,
  Statistic,
  Image,
  Back,
  P
}

const CustomerStory: React.FC<PageProps & Query> = props => {
  const { mdx } = props.data

  const seo: SeoObject = {
    title: mdx.frontmatter.title,
    description: mdx.frontmatter.metaDescription
  }

  return (
    <Site seo={seo} {...props}>
      <Main>
        <MDXProvider components={shortcodes}>
          <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Main>
      <Footer />
    </Site>
  )
}

export const pageQuery = graphql`
  query Story($uid: String!) {
    mdx(frontmatter: { slug: { eq: $uid } }) {
      frontmatter {
        id
        title
        name
        metaDescription
        customerUrl
      }
      body
    }
  }
`

export default CustomerStory
